export default{
    bgBluePrimary:"#2196f3",
    bgBlueDark:"#1769aa",
    bgBlueLight:"#4dabf5",
    bgPinkPrimary:"#ff5c5c",
    bgYellow:"#fdbe11",

    bgAppBar:"rebeccapurple",

    txtYellow:"#ffda12",

    purple:"#632b9b",
    darkPink:"#bb268b",
    peach:"#f14a71",
    orange:"#ff8258",
    oyellow:"#ffbe51",
    yellow:"#f9f871",
    biscuit:"#fffade",
    

};