
import React,{useState}from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Colors from './Constants/Colors';
import { green } from '@material-ui/core/colors';
import DBConnection from './Components/DBConnection';
import { CircularProgress } from '@material-ui/core';
const dbcon = new DBConnection();
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://learnisl.com/">
        LearnISL
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(./loginPoster.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'initial',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: Colors.yellow,
    color: Colors.purple,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    textAlign:'center',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonDiv:{
      display: 'flex',
      flexDirection:'column',
  },
  submit: {
    margin: 12,
    backgroundColor: Colors.peach,
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
    }
  }

  authenticate = async (email, pswd) => {
    this.setState({
      loading: true,
      success: false,
    });
    var result = await dbcon.authenticate(email, pswd);
    this.setState({
      success: true,
      loading: false,

    });

    if (result.Success === true) {
      console.log("Success");
      if (result.Role === "1") {
        localStorage.setItem('role', 'Admin');
        localStorage.setItem('name', result.UserName);
        //localStorage.setItem('jwt', result.jwt);
        this.props.history.push('/AdminIndex');
        //history.push('/AdminIndex');
      }
      
      else {
        console.log("Student");

        localStorage.setItem('role', 'Student');
        localStorage.setItem('name', result.UserName);
       // localStorage.setItem('jwt', result.jwt);
        this.props.history.push('/');
      }
    }


  }


  render() {
    localStorage.clear();
    const { classes } = this.props;

    let email, pswd;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={8} className={classes.image} />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square style={{ backgroundColor: Colors.biscuit }}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              ISL
          </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
          </Typography>
            <form
              method="post"
              className={classes.form}
              noValidate
              onSubmit={e => {
                e.preventDefault();
                this.authenticate(email.value, pswd.value);
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                inputRef={node => {
                  email = node;
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={node => {
                  pswd = node;
                }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"

              />
              <div className={classes.buttonDiv}>
              <div className={classes.wrapper}>
                <Button
                  type="submit"

                  variant="contained"
                  color="primary"
                  className={(this.state.success) ? classes.buttonSuccess : classes.submit}
                  disabled={this.state.loading}
                //onClick={this.handleButtonClick}
                >
                  Sign In
              </Button>
                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>

              </div>

              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(SignIn);