import React, { Component, Fragment,forwardRef } from 'react'

import MaterialTable from 'material-table';
import { Paper, IconButton, Link } from '@material-ui/core';
import DBConnection from '../Components/DBConnection';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PlayIcon from '@material-ui/icons/PlayArrow';

const dbCon = new DBConnection();
const rows=[];




const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

//   const useStyles = theme => ({
  
// });
export default class TableVideoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tabledata: [],
          lectureID: 0,
    
        }
      }
      componentWillMount() {
        this.load();
      }
      openDeleteLectureConfirm = async(params) => {
        var r = window.confirm("Do you want to delete this Record?");
        if (r === true) {
          var result= await dbCon.deleteLecture(params);
          if(result.Success)
          {
              this.load();
            alert("Record deleted successfully");
          }
        } 
      }
      

      load=async ()=> {
        var result = await dbCon.loadAllLectures();
        if(result.length>0)
        {

        rows.splice(0, rows.length);
    
            console.log(result);
          this.setState({
            tabledata: result
          })
          console.log(this.state.tabledata);
        }
    }

    
        
    render() {
        return (
            

            <MaterialTable
            
            icons={tableIcons}
              title="Editable Example"
              columns={[
                { title: 'Sr.', field: 'id', width:"5%"},
                { title: 'Title', field: 'Lecture',width:"55%" },
                { title: 'Class', field: 'ClassN',width:"7%" },
                { title: 'Subject', field: 'SubjectN' ,width:"8%"},

                
                {
                  title: 'Options', render: rowData =>
                    <Fragment>
                    <Link href={rowData.ClassN+"/"+rowData.Video} target={"_blank"}>
                    <IconButton aria-label="delete" size={"small"}>
                        <PlayIcon />
                      </IconButton>
                      </Link>
                      <IconButton aria-label="delete" size={"small"} onClick={()=> {this.openDeleteLectureConfirm(rowData.id)}}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton aria-label="Edit" size={"small"}
                      onClick={() => { this.handleOpenEdit(rowData) }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Fragment>
                    , width:"25%"
                },
  
              ]}
              data={this.state.tabledata}
  
            />




        )
    }
}
