import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Drawer, Hidden, IconButton, Toolbar, Collapse, Box, Menu, MenuItem } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';

import Colors from '../Constants/Colors';
import Data from '../Constants/DataSbjClass.json';
//import LecturePlay from './LecturePlay'
import { Redirect } from 'react-router-dom';
import Dashboard from './Dashboard';
import Upload from './Upload';


const drawerWidth = 240;
const useStyles = theme => ({
    typography: {
        flexGrow: 1,
        align: "center"
    },


    sideBarLink: {
        color: '#000',
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        backgroundColor: Colors.bgAppBar,
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: Colors.biscuit,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
});
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            open: false,
            auth: true,
            page:"Upload",
        }
        this.child = React.createRef();
    }

    handleDrawerToggle = () => {
        this.setState({
            mobileOpen: !(this.state.mobileOpen)
        })
    }

 
    handleMenuPopup = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            openMenuPopup: true,
        });

    };

    handleCloseMenuPopup = () => {
        this.setState({
            anchorEl: null,
            openMenuPopup: false,
        })
    };
    handleLogout = () => {
        this.handleCloseMenuPopup();
        localStorage.clear();
    }
    handleUpload =() =>{
        this.setState({
            page:"Upload",
        })
        

    }
    handleDashboard = () => {
        this.setState({
            page:"Dashboard",
        })
        
    }
    

    render() {
        const { classes } = this.props;
         
        if (localStorage.getItem('role') !== "Admin") {
            return <Redirect to="/SignIn" push={true} />
        }
        const drawer = (
            <div>
                <List>
                    <ListItem button onClick={() => { this.handleDashboard() }}>
                        <ListItemIcon>
                            <AddCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />

                    </ListItem>

                    <ListItem button onClick={() => { this.handleUpload() }}>
                    <ListItemIcon>
                        <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Upload"} />

                </ListItem>
                </List>

            </div >
        );

        if (localStorage.getItem('name') === null) {
            return <Redirect to="/SignIn" push={true} />
        }

        return (

            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box style={{ flexGrow: 1 }}>
                            <Typography display={"inline"} style={{ color: Colors.txtYellow }}>Learn </Typography>
                            <Typography display={"inline"} className={classes.typography} align={"center"}> Indian Sign Language</Typography>
                        </Box>
                        {this.state.auth && (
                            <div>
                                <IconButton
                                    style={{ marginRight: 0 }}
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleMenuPopup}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={this.state.openMenuPopup}
                                    onClose={this.handleCloseMenuPopup}
                                >
                                    <MenuItem onClick={this.handleCloseMenuPopup}>Profile</MenuItem>
                                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>

                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={"left"} //{theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <IconButton onClick={this.state.handleDrawerToggle} className={classes.closeMenuButton}>
                                <CloseIcon />
                            </IconButton>
                            {drawer}
                        </Drawer>
                    </Hidden><Hidden xsDown implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.toolbar} />
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                {/* Content Area */}
                <div className={classes.content}>
                    <div className={classes.toolbar} />
                    {(this.state.page==="Dashboard")?<Dashboard />:null}
                    {(this.state.page==="Upload")?<Upload />:null}
                </div>
            </div>
        );
    }
}

Index.propTypes = {

    container: PropTypes.object,
};
export default withStyles(useStyles)(Index);

