import React, { Component } from 'react'
import { Paper, Grid, withStyles, ListItem, List, ListItemText, ListItemIcon, Card, CardContent, CardHeader, Avatar, Button, Divider } from '@material-ui/core'
import ImportContacts from '@material-ui/icons/ImportContacts';
import ReactPlayer from 'react-player';
import Colors from './Constants/Colors';
import DBConnection from './Components/DBConnection';
import API from './Constants/API';
const dbCon=new DBConnection();
const useStyles = theme => ({
    thumbnail: {
        padding: 3,
        borderRadius: 5,
    },
    lectureList: {
        padding: 4,

    },
    avatar:{
        background:"#ffda12",
        color:"#639",
    },
    videoPlayer:{
     //   minHeight:200,
    }

   
})
class LecturePlay extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            currentSubject:"",
            currentClass:"",
            currentLectureVideo:'#',
            currentLectureTitle:"",
            currentLectureID:0,
            lectures:null,
        }
    }
   
    loadAllLectures = async (key,SubjectN,ClassN) => {
        this.setState({
            currentSubject:SubjectN,
            currentClass:ClassN,
        })
        const result= await dbCon.loadAllLecturesBySubject(key);
        //console.log(result);
        if(result.length>0)
        {
            console.log("not null" + result);
        this.setState({
            lectures:result,
        });
    }
        else
        {
            console.log("Null");
            this.setState({
                lectures:null
            })
        }

      //  console.log(result);
    }
    onSelectLecture = (row) => {
        this.setState({
            currentLectureTitle:row.Lecture,
            currentLectureID:row.id,
            currentLectureVideo:API.VideoPath+this.state.currentClass+"/"+row.Video,
        })
        console.log(row);
    }
    
    ref = player => {
        this.player = player
      }
    
    render() {
        const { classes } = this.props;
        const ref = React.createRef();
        return (

            <Grid container spacing={3}>
                <Grid item xs={12} md={7} alignItems="center">
                    <Paper
                        className={classes.thumbnail}
                        variant="outlined"
                        style={{textAlign:"center"}}
                    >
                        <ReactPlayer
                        ref={this.ref}
                            playsinline
                            width={"100%"}
                            height={"100%"}
                            controls={true}
                            playing={true}
                            url={this.state.currentLectureVideo}
                            className={classes.videoPlayer}
                            config={{ 
                                file: { 
                                  attributes: {
                                    onContextMenu: e => e.preventDefault(),
                                    controlsList: 'nodownload' 
                                  } 
                                } 
                              }}
                        />
                        <Divider />
                        <Button onClick={()=>{this.player.seekTo(parseFloat(0)); this.player.playing=true}} style={{backgroundColor:Colors.peach, color:"#fff", margin:5}}>Play Again</Button>
                    </Paper>
                </Grid>
                <Grid item md={5} xs={12}>
                    <Card
                        className={classes.thumbnail}
                        
                    >
                        <CardHeader
                        style={{backgroundColor:Colors.peach, color:"#fff"}}
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                              ISL
                            </Avatar>
                          }
                            title={this.state.currentLectureTitle}
                            subheader={this.state.currentSubject +" ["+this.state.currentClass +"]"}
                        />
                        <CardContent>
                            <List>
                            {this.state.lectures != null ? this.state.lectures.map((row) => (
                                <ListItem key={row.id} className={classes.lectureList} onClick={()=>{this.onSelectLecture(row)}}>
                                <ListItemIcon>
                                    <ImportContacts />
                                </ListItemIcon>
                                <ListItemText>
                                    {row.Lecture}
                                </ListItemText>
                            </ListItem>

                              

                            )) : null}
                             
                            </List>
                        </CardContent>

                    </Card>
                </Grid>

            </Grid>

        )
    }
}

export default withStyles(useStyles)(LecturePlay);