import React, { Component } from 'react'
import { Typography, Grid, Card, CardHeader, CardContent, Button, Table, Avatar, withStyles, TextField, FormControl, InputLabel, Select, MenuItem, Box, Divider } from '@material-ui/core'
import Colors from '../Constants/Colors'
import Data from '../Constants/DataSbjClass.json';
import DBConnection from '../Components/DBConnection';
import TableVideoList from './TableVideoList';
import { Redirect } from 'react-router-dom';
const dbCon=new DBConnection();


const useStyles = theme => ({
    avatar: {
        background: "#ffda12",
        color: "#639",
    },
    textField: {
        width: "100%",
    },
    select: {
        width: 150,
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button:{
        backgroundColor:Colors.bgBlueDark
    },
});
class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Title: "",
            UFile: null,
            USbjID: 0,
            UClass:"",
            subject_list: null,
        };
        this.child = React.createRef();

    }
    uploadProgress = (uploaded) => {
        this.setState({ progress: uploaded })
    }

    upload = async () => {
        var result=await dbCon.upload(this.state.Title,this.state.USbjID,this.state.UFile,this.state.UClass, this.uploadProgress);
        if(result.Success)
        {
            this.child.current.load();
            alert("Record inserted successfully");
        }
        console.log(result);
    }
    
    loadSubjects = (params) => {

        const subjects = Data.filter((cls) => cls.ClassN === params);
        this.setState({
            UClass:params,
            subject_list: subjects[0].Subject,
        });

    }

    render() {


      
        const { classes } = this.props;

        return (
            <div>
                <Grid container spacing={1} >
                    <Grid item xs={12} md={5}>
                        <Card>
                            <CardHeader
                                style={{ backgroundColor: Colors.darkPink, color: "#fff" }}
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        ISL
                            </Avatar>
                                }
                                title={"Upload Lecture"}
                            />
                            <CardContent>

                                <form noValidate className={classes.root} onSubmit={e => {
                                    e.preventDefault();
                                    this.upload();
                                }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        id="title"
                                        label="Lecture Title"
                                        className={classes.textField}
                                        name="title"
                                        autoFocus
                                        onChange={(e) => {
                                            this.setState({
                                                Title: e.target.value
                                            })
                                        }}
                                        value={this.state.Title}
                                    />
                                    <Box className={classes.textField}>

                                        <FormControl margin="dense"
                                            variant="outlined">
                                            <InputLabel id="class-list-label">Class</InputLabel>
                                            <Select
                                                labelId="class-list"
                                                id="cls_id"
                                                value={this.state.ClassID}

                                                autoWidth={true}
                                                className={classes.select}
                                                onChange={(e) => {

                                                    this.loadSubjects(e.target.value);
                                                }}
                                            >

                                                {Data.map((row) => (
                                                    <MenuItem key={row.ClassN} value={row.ClassN}>{row.ClassN}</MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl margin="dense"
                                            variant="outlined">
                                            <InputLabel id="subject-list-label">Subject</InputLabel>
                                            <Select
                                                labelId="subject-list"
                                                id="sbj_id"
                                                value={this.state.ClassID}

                                                autoWidth={true}
                                                className={classes.select}
                                                onChange={(e) => {

                                                    this.setState({ USbjID: e.target.value })
                                                }}
                                            >

                                                {(this.state.subject_list != null) ? this.state.subject_list.map((row) => (
                                                    <MenuItem key={row.SbjId} value={row.SbjId}>{row.SubjectN}</MenuItem>

                                                )) : null}
                                            </Select>
                                        </FormControl>

                                    </Box>
                                    <TextField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    id="url"
                                    type="file"
                                    
    
                                    name="url"
                                    onChange={(e) => {
                                        this.setState({
                                            UFile: e.target.files[0]
                                        })
                                    }}
    
    
                                />
                                <Divider style={{margin:10}}></Divider>
                                <Box className={classes.textField} textAlign={"center"}>
                                <h1>Uploaded- {this.state.progress} %</h1>
                                {
                                    (this.state.edit) ?
                                        <Button
                                            type="button"
                                            variant="contained"
                                            
                                            onClick={this.updateRecord}
                                            className={classes.button}
                                        >
                                            Update
                              </Button>
                                        :
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color={"primary"}
                                            className={classes.button}
                                        >
                                            Submit
                              </Button>
                                }

                            </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Card>
                            <CardHeader
                                style={{ backgroundColor: Colors.peach, color: "#fff" }}
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        ISL
                                    </Avatar>
                                }
                                title={"List of Lectures"}
                            />
                            <CardContent>
                                <TableVideoList ref={this.child}/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(useStyles)(Upload);
