import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
import SignIn from './MyApp/SignIn';
import Index from './MyApp/Index';
import AdminIndex from './MyApp/Admin/Index';

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={Index} />
      <Route exact path="/SignIn" component={SignIn} />
      <Route exact path="/AdminIndex" component={AdminIndex} />

      {/* <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/videolectures/:sbjid/:subjectname/" component={VideoLectures} />
    */}



    </div>
  </Router>
)

ReactDOM.render(
  routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


