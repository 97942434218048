import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

export default class Dashboard extends Component {
    render() {
        return (
            <div>
                <Typography>Dashboard</Typography>
            </div>
        )
    }
}
